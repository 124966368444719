.app {
  height: 100%;
}

html, body, #root, .App {
  height: 100%;
}



 .container {
    position: relative;
  }
  
  .page {
    position: absolute;
    /* left: 15px;
    right: 15px; */
  }
  
  .page-enter {
    opacity: 0;
  }
  
  .page-enter-active {
    opacity: 1;
    transition: opacity 400ms;
  }
  
  .page-exit {
    opacity: 1;
  }
  
  .page-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }
  